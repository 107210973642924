<template>
  <v-container fluid class="search">
    <ProductListGrid
      :query="query"
      :barcode="barcode"
      :parentCategoryId="parent_category_id"
      :key="key"
      mode="search"
      @productsCount="updateCount"
      @searchedText="updateRealSearch"
      position="search"
    >
      <template #category-title>
        <div>
          <h1
            class="primary--text text--lighten-1 text-center text-sm-left font-weight-bold mt-4 mt-sm-0"
          >
            Risultati ricerca per {{ realSearch || query }}
          </h1>
          <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
          <p class="subtitle-2" v-if="realSearch">
            Avevi cercato
            <a class="real_search">{{ query }}</a>
            ma non ha prodotto risultati.
          </p>
        </div>
      </template>
      <template #category-block-position-1>
        <category-block
          :target="category"
          position="position1"
          class="category-block category-block-1"
          :container="false"
        />
      </template>
      <template #category-block-position-3>
        <category-block
          :target="category"
          position="position3"
          class="category-block category-block-3"
          :container="false"
        />
      </template>
      <template #category-block-position-4>
        <category-block
          :target="category"
          position="position4"
          class="category-block category-block-4"
          :container="false"
        />
      </template>
    </ProductListGrid>

    <v-container v-if="count == 0">
      <span v-if="promoFilter">
        Non sono presenti prodotti in promozione per la ricerca di
        <strong> {{ query }} </strong>.
      </span>
      <span v-if="!promoFilter">
        La ricerca di <strong>{{ query }}</strong> non ha prodotto risultati.
      </span>
    </v-container>
  </v-container>
</template>
<style scoped lang="scss">
h1 {
  color: var(--v-primary-lighten-1);
  font-weight: normal;
}
.real_search {
  cursor: text;
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";

import { mapActions, mapGetters } from "vuex";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "SearchProducts",
  // props: ["query", "parent_category_id"],
  components: {
    ProductListGrid
  },
  data() {
    return {
      key: 0,
      count: -1,
      query: null,
      barcode: null,
      parent_category_id: null,
      realSearch: null
    };
  },
  mixins: [categoryMixins, deliveryReactive],
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter"
    })
  },
  methods: {
    historyBack() {
      window.history.back();
    },
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
    updateCount(count) {
      this.count = count;
    },
    updateRealSearch(searchedText) {
      this.realSearch = searchedText;
    },
    getQueryParams() {
      this.query = this.$route.query.q;
      this.barcode = this.$route.query.barcode;
      // this.queryLabel = this.$route.query.barcode
      //   ? this.$route.query.barcode
      //   : this.$route.query.q;
      this.parent_category_id = this.$route.query.parent_category_id;
      this.filter = this.$route.query.filter;
    },
    reload() {
      this.$store.dispatch("category/resetFilters");
      this.key = this.key + 1;
    }
  },
  async created() {
    this.getQueryParams();
    await this.resetFilters("q=" + this.query);
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
      this.key = this.key + 1;
      this.realSearch = null;
      this.count = null;
      await this.resetFilters("q=" + this.query);
    }
  },
  beforeRouteLeave(to, from, next) {
    global.EventBus.$emit("clearSearch");
    next();
  }
};
</script>
